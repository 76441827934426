/* NotificationAlert.css */

.notification-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.notification-alert {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin-bottom: 10px;
  padding: 15px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.notification-alert.exiting {
  opacity: 0;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.notification-title {
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.notification-body {
  padding-top: 10px;
  border-top: 1px solid #eee;
}

/* src/components/Activity/ActivityPage.css */

.activity-page {
  padding: 20px;
}

.activity-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.activity-table th,
.activity-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.activity-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.activity-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.activity-table tbody tr:hover {
  background-color: #ddd;
}

.activity-table tfoot tr {
  font-weight: bold;
}
